import axios from 'axios'

//自动加在url前面
axios.defaults.baseURL = 'https://company.fxd1.com.cn'
//超时时间
axios.defaults.timeout = 5000
//跨域凭证
axios.defaults.withCredentials = false

//响应和请求 拦截器配置

export default axios
