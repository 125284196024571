<template>
  <div class="bg">
    <div class="banner">
      <div class="banner_info container">
        <div class="banner_infoTitle">
          <span class="banner_infoTitleBox">数字化绿色化咨询<i class="banner_icon">+</i></span>
          碳核查核算
        </div>
        <div class="banner_infoTitleText">专注助力企业评价+获证一站式服务发展低碳发展和碳生态协作共赢</div>
        <div class="banner_button" @click="chat">免费咨询</div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solve container">
      <div class="content">
        <div class="title">企业“三化”发展咨询+解决方案企业“三化”发展咨询+解决方案</div>
        <div class="cont">
          基于行业、企业量身定制专属解决方案，推动制造业高端化、智能化、绿色化发展。
        </div>
      </div>
      <div class="content">
        <div class="title">产品碳足迹核算/企业温室气体核查服务</div>
        <div class="cont">
          联合垂直行业打造标准LCA模型;高效产品碳足迹计算和全生命周期分析。
        </div>
      </div>
    </div>
    <div class="method">
      <div class="architect">
        <div class="container">
          <div class="title">企业“三化”发展咨询+解决方案方法架构</div>
          <div class="info">基于区块链、数据安全和隐私计算，通过提供高效、精准、低成本的碳排放管理、<br>
            产品碳足迹和可信生态服务，助力企业、组织数字化低碳转型和碳数据价值流转</div>
          <div class="architect_line"></div>
          <div class="module">
            <div>
              <div class="module_title">工业绿色化架构</div>
              <img src="../../../static/architect1.png">
            </div>
            <div>
              <div class="module_title">工业绿色化架构</div>
              <img src="../../../static/architect2.png">
            </div>
            <div>
              <div class="module_title">工业绿色化架构</div>
              <img src="../../../static/architect3.png">
            </div>
          </div>
          <div class="scene">
            <div class="scene_title">数字赋能绿色场景</div>
            <div>
              <img src="../../../static/architect4.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_method">
      <div class="method_title">企业“三化”发展咨询+解决方案方法架构</div>
      <div class="swiper">
        <div class="swiper_title">{{data.swiper_title}}</div>
        <div class="swiper_img">
          <img :src="data.swiper_img">
        </div>
        <div class="prev">
          <div @click="toPrevThree(data.swiper_current-1)">
            <img src="../../../static/icon/icon_left.png" alt="">
          </div>
          <div @click="toPrevThree(data.swiper_current+1)">
            <img src="../../../static/icon/icon_right.png" alt="">
          </div>
        </div>
      </div>
    </div>
<!--  服务  -->
    <div class="service">
      <div class="container">
        <div class="service_title">评价 + 获证一站式服务</div>
        <div class="service_title_txt">十多年的专业技术积累 认证 检测 分析 研发 一站式技术服务</div>
        <div class="service_content">
          <div class="item" v-for="(item,index) in data.service_icon" :key="index">
            <div>
              <img :src="item.url">
            </div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
        <div class="flow_title">产品碳足迹核算/企业温室气体核查的流程内容</div>
        <div class="flow_one_title">步骤一：五纪时代（中国信通院）评价</div>
        <div class="flow_one">
          <div class="item" v-for="(item,index) in data.flow1_list" :key="index">
            <div class="text">{{ item }}</div>
            <div>
              <img src="../../../static/icon/arrow.png">
            </div>
          </div>
          <div class="last_item">出具<br>报告</div>
        </div>
        <div class="flow_one_title">步骤二：五纪时代认证中心（中国信通院）颁证</div>
        <div class="flow_one">
          <div class="item" v-for="(item,index) in data.flow2_list" :key="index">
            <div class="text">{{ item }}</div>
            <div>
              <img src="../../../static/icon/arrow.png">
            </div>
          </div>
          <div class="last_item">颁发<br>证书</div>
        </div>
        <div class="certificate">
          <div>
            <img src="../../../static/certificate.png">
          </div>
          <div>已为多家企业出具评价报告和颁发证书</div>
        </div>
      </div>
    </div>
<!--  手机流程页面  -->
    <div class="company arrow">
      <div class="arrow-title" >
        <div >评价+获证一站式服务</div>
      </div>
      <el-row :gutter="5" class="arrow-row">
        <el-col :span="8"  v-for="(item,index) in data.service_icon" :key="index">
          <div class="arrow-row-div">
            <img :src="item.url" :alt="item.title">
            <span>{{item.title}}</span>
          </div>
        </el-col>
      </el-row>
      <div class="second-title">
        <div class="stitle">产品碳足迹核算/企业温室气体核查的流程内容</div>
        <div class="sline"></div>
      </div>

      <div class="second-wrap">
        <div class="stitle">步骤一：五纪时代（中国信通院）评价</div>
        <div class="ssecond">
          <div class="ssecond-row">
            <div class="squire"><span>全程</span><span>辅导</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>委托</span><span>协议</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>资料</span><span>填写</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>开展</span><span>评价</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire last"><span>出具</span><span>报告</span></div>
          </div>
        </div>
        <div class="stitle">步骤二：五纪时代认证中心（中国信通院）颁证</div>
        <div class="ssecond">
          <div class="ssecond-row">
            <div class="squire"><span>申请</span><span>评价</span></div>
            <div class="right"><img src="../../../static/icon/icon_arrow.png"></div>
            <div class="squire"><span>签订</span><span>合同</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>审核</span><span>计划</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>文件</span><span>审核</span></div>
            <div class="right"><img src="../../../static/icon/arrow.png"></div>
            <div class="squire"><span>现场</span><span>审核</span></div>
          </div>
        </div>
        <div class="sbottom">
          <div class="down"><img src="../../../static/icon/icon_down.png"></div>
          <div class="scert">
            <div class="cert">
              <img src="../../../static/certificate.png" />
              <div>已为多家企业出具评价报告和颁发证书</div>
            </div>
            <div class="srow">
              <div class="squire"><span>审核</span><span>评定</span></div>
              <div class=""><img src="../../../static/icon/icon_down.png"></div>
              <div class="squire last"><span>颁发证书</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--  行业标准  -->
    <div class="standard">
      <div class="container">
        <div class="standard_title">我们坚持和遵循的行业标准</div>
        <div class="standard_box">
          <div class="standard_item" v-for="(item,index) in data.standard_list" :key="index">
            <img :src="item">
          </div>
        </div>
      </div>
    </div>

  </div>
  <is-footer/>
</template>
<script setup>
import {onMounted, ref,getCurrentInstance} from "vue";
import IsFooter from "@/components/IsFooter.vue";
const { proxy }= getCurrentInstance();

  let data = ref({
    service_icon:[{
      title:'链主企业认可',
      url:require('/static/icon/service_icon1.png')
    },{
      title:'评价案例丰富',
      url:require('/static/icon/service_icon2.png')
    },{
      title:'费用优惠',
      url:require('/static/icon/service_icon3.png')
    },{
      title:'全程辅导',
      url:require('/static/icon/service_icon4.png')
    },{
      title:'行业口碑好',
      url:require('/static/icon/service_icon5.png')
    },{
      title:'时间快',
      url:require('/static/icon/service_icon6.png')
    }],
    flow1_list:['申请评价','委托协议','资料填写','开展评论'],
    flow2_list:['申请评价','签订评价','审核计划','文件审核','现场审核','审核评定'],
    standard_list:[require('/static/standard1.png'),require('/static/standard2.png'),require('/static/standard3.png'),
      require('/static/standard4.png'),require('/static/standard5.png')],
    swiper_list:[{
      title:'工业绿色化架构',
      url:require('/static/architect1.png')
    },{
      title:'数字赋能绿色贡献量化模型',
      url:require('/static/architect2.png')
    },{
      title:'数字赋能绿色赋能',
      url:require('/static/architect3.png')
    },{
      title:'数字赋能绿色场景',
      url:require('/static/architect4.png')
    }],
    swiper_title:'',
    swiper_img:'',
    swiper_current:1
  })
onMounted(()=>{
  data.value.swiper_title = data.value.swiper_list[0].title
  data.value.swiper_img = data.value.swiper_list[0].url
})
const toPrevThree = (v) => {
  if(v>0 && v<5){
    data.value.swiper_current = v
    data.value.swiper_title = data.value.swiper_list[v-1].title
    data.value.swiper_img = data.value.swiper_list[v-1].url
  }
}
const chat = ()=>{
  proxy.$open53()
}
</script>
<style scoped>
.bg{
  background-color: #fbfcfe;
}
.arrow{
  display: none;
}
.mobile_method{
  display: none;
}
  .banner{
    height: 530px;
    background: url("/static/index_banner.png") no-repeat;
    background-size: 100% 530px;
  }
  .banner_info{
    padding-top: 120px;
  }
  .banner_infoTitle{
    font-size: 44px;
    font-weight: 700;
  }
  .banner_infoTitleBox{
    position: relative;
    margin-right: 30px;
  }
  .banner_infoTitleBox i{
    position: absolute;
    top: -20px;
    right: -35px;
    display: inline-block;
    font-weight: 700;
    color: #3fee97;
    font-style: normal;
  }
  .banner_infoTitleText{
    font-size: 14px;
    margin-top: 20px;
    font-weight: 700;
    color: #666;
  }
  .banner_button{
    margin-top: 30px;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    padding: 15px 30px;
    font-weight: 700;
    border-radius: 40px;
    background-color: #3fee97;
  }
  .banner_button:hover{
    background-color: #62e3a8;
    cursor: pointer; /* 将鼠标指针样式设置为手型 */
  }
  .solve{
    margin-top: -170px;
    display: flex;
    justify-content: space-between;
  }
  .solve .content{
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 30px 50px;
    width: 590px;
    box-shadow: 0 0 6px rgba(114, 103, 103, 0.05);
  }
  .solve .content .title{
    height: 75px;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 40px;
  }
  .solve .content .cont{
    font-size: 18px;
    color: #969696;
  }
  .architect{
    padding-top: 55px;
    text-align: center;
    background-color: #fff;
    padding-bottom: 50px;
  }
  .architect .title{
    font-size: 40px;
  }
  .architect .info{
    color: #969696;
    margin-top: 20px;
  }
  .architect_line{
    position: relative;
    height: 2px;
    background-color: #0bbb38;
    margin: 80px 0 20px;
  }
  .architect_line:before,.architect_line:after{
    content: '';
    display: inline-block;
    position: absolute;
    right: 0;
    top: -3px;
    height: 8px;
    width: 68px;
    background-color: #0bbb38;
    border-radius: 4px;
  }
  .architect_line:after{
    left: 0;
  }
  .module{
    display: flex;
    justify-content: space-between;
  }
  .module .module_title{
    font-size: 24px;
    font-weight: 700;
    padding: 80px 0 50px;
  }
  .module div:nth-child(2) .module_title{
    padding: 20px 0;
  }
.scene_title{
  font-size: 24px;
  font-weight: 700;
  padding: 20px 0;
}
.scene div{
  display: flex;
  justify-content: center;
}
.service{
  background-color: #f4f7fc;
}
.service_title{
  font-size: 40px;
  text-align: center;
  padding-top: 75px;
}
.service_title_txt{
  color: #969696;
  text-align: center;
  margin: 20px 30px;
}
.service_content{
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 0 50px;
}
.service_content .item .text{
  font-size: 24px;
  padding: 14px 0;
  font-weight: 700;
}

.flow_title{
  position: relative;
  font-size: 25px;
  text-align: center;
  margin-top: 40px;
  padding: 30px 0;
}
.flow_title:after{
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -35px;
  content: '';
  display: inline-block;
  width:70px;
  height: 10px;
  background-color: #0bbb38;
  border-radius: 5px;
}
.flow_one{
  display: flex;
  align-items: center;
  font-size: 19px;
}
.flow_one >div{
  display: flex;
  align-items: center;
}
.flow_one .item .text{
  padding: 15px 25px;
  border-radius: 30px;
  background-color: #79cf78;
  color: #fff;
}
.flow_one .last_item{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #5ead76;
  justify-content: center;
  color: #fff;

}
.flow_one_title{
  color: #969696;
  padding: 30px 10px 10px;
}
.certificate{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 100px;
  font-size: 25px;
  color: #262827;
}
.standard{
  padding: 80px 0;
  background-image: linear-gradient(310deg, rgb(243, 243, 255) 0%, rgb(207, 223, 234) 100%);
}
.standard_title{
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  padding: 80px 0;
  letter-spacing: 6px;
}
.standard_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}
.standard_item{
  width: 220px;
  height: 200px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
}
.standard_item img{
  width: 160px;
  height: 200px;
}
@media (max-width:1000px) {
  .arrow{
    display: block;
  }
  .mobile_method{
    display: block;
  }
  .banner{
    height: 150px;
    background: url("/static/index_banner.png") no-repeat;
    background-size: 100% 150px;
  }
  .banner_info{
    box-sizing: border-box;
    padding: 20px 20px;
  }
  .banner_infoTitle{
    font-size: 14px;
    font-weight: 700;
  }
  .banner_infoTitleBox{
    position: relative;
    margin-right: 10px;
  }
  .banner_infoTitleBox i{
    position: absolute;
    top: -10px;
    right: -10px;
    display: inline-block;
    font-weight: 700;
    color: #3fee97;
    font-style: normal;
  }
  .banner_infoTitleText{
    font-size: 8px;
    margin-top: 10px;
    font-weight: 700;
    color: #666;
  }
  .banner_button{
    margin-top: 10px;
    display: inline-block;
    color: #fff;
    font-size: 10px;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 20px;
    background-color: #3fee97;
  }
  .solve{
    box-sizing: border-box;
    margin-top: -10px;
    padding: 0 20px;
  }
  .solve .content{
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    width: 49%;
    box-shadow: 0 0 6px rgba(114, 103, 103, 0.05);
  }
  .solve .content .title{
    height: auto;
    font-size: .9rem;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 20px;
    color: #333;
  }
  .solve .content .cont{
    margin-top: 10px;
    color: grey;
    font-weight: 500;
    height: auto;
    text-align: left;
    font-size: .6rem;
    line-height: 1.2rem;
  }
  .method{
    display: none;
  }
  .mobile_method{
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    margin: 15px 20px;
  }
  .method_title {
    width: 100%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1rem;
    color: #333333;
    border-bottom: 3px solid #79cb76;
    position: relative;
  }

  .method_title:before {
    content: "";
    background-color: #79cb76;
    width: 30%;
    height: 5px;
    position: absolute;
    top: 100%;
    margin-top: -5px;
  }
  .swiper{
    padding: 10px;
  }
  .swiper_title{
    font-weight: 700;
    font-size: 20px;
    margin-left: 15px;
    color: #5ead76;
    margin-bottom: 10px;
  }
  .swiper_img{
    display: flex;
    justify-content: center;
  }
  .swiper_img img{
    width: auto;
    max-width: 310px;
    height: 197px;
  }
  .swiper .prev{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-right: 5px;
  }
  .swiper .prev img{
    width: 50px;
    height: 50px;
  }
  .swiper .img .prev{
    position: absolute;
    right: 0;
    bottom: -5px;
  }
  .service{
    display: none;
  }
  /* 流程 */
  .arrow .arrow-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 15px;
  }
  .arrow .arrow-title img{
    width: 60px;
  }
  .arrow .arrow-title div{
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    color: #5EAD76;
  }
  .arrow .arrow-row{
    font-size: 10px;
    color: #808080;
    padding: 0 5px 10px 5px;
  }

  .arrow .arrow-row .arrow-row-div{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  .arrow .arrow-row .arrow-row-div img{
    width: 20px;
  }
  .arrow .arrow-row .arrow-row-div span{
    margin-left: 3px;
  }
  .arrow .second-title{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px auto 0;
  }
  .arrow .second-title .stitle{
    font-weight: 600;
    font-size: 14px;
    color: #5EAD76;
  }
  .arrow .second-title .sline{
    width: 100px;
    height: 5px;
    background-color: #5EAD76;
    margin-top: 10px;
  }
  .arrow .second-wrap{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 15px 20px 15px ;
  }
  .arrow .second-wrap .stitle{
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    padding: 10px 0;
    text-align: left;
  }
  .arrow .ssecond{
    width: calc(100vw - 30px - 45px);
    display: flex;
    align-items: center;
  }
  .arrow .ssecond .ssecond-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .arrow .ssecond .right{
    margin: 0 3px;
  }
  .arrow .ssecond img{
    width: 18px;
  }
  .arrow .ssecond .squire{
    border-radius: 5px;
    background-color: #79cf78;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    height: 40px;
  }
  .arrow .ssecond .ssecond-row .last{
    border-radius: 50%;
    background-color: #5EAD76;
    width: 45px;
    height: 45px;
  }
  .arrow .ssecond .ssecond-row .squire span{
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    transform:scale(0.9);
  }
  .arrow .down{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
  }
  .arrow  .sbottom{
    width: calc(100vw - 30px - 45px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .arrow .sbottom .down{
    display: flex;
    margin-right: 29px;
    width: 100%;
  }
  .arrow .sbottom .down img{
    height: 18px;
    margin-bottom: 5px;
  }

  .arrow .sbottom .scert{
    display: flex;
    align-items: center;
    width: calc(100vw - 30px - 45px);
  }
  .arrow .sbottom .scert .cert{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 3;
  }
  .arrow .sbottom .scert .cert img{
    width: 230px;
    margin-bottom: 10px;
  }
  .arrow .sbottom .scert .cert div{
    font-weight: 500;
    font-size: 12px;
    color: #5EAD76;
  }
  .arrow .sbottom .scert .srow{
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    max-width: 40px;
  }
  .arrow .sbottom .scert .srow img{
    margin-top: 10px;
    margin-bottom: 3px;
    height: 18px;
  }

  .arrow .sbottom .scert .srow .squire{
    border-radius: 5px;
    background-color: #79cf78;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40px;
    height: 40px;
  }
  .arrow .sbottom .scert .srow .squire span{
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    transform:scale(0.9);
  }
  .arrow .sbottom .scert .srow .last{
    width: 60px;
    height: 40px;
    background-color: #5EAD76;
  }
  /** 行业标准 **/
  .standard{
    padding: 10px 0;
    background-image: linear-gradient(310deg, rgb(243, 243, 255) 0%, rgb(207, 223, 234) 100%);
  }
  .standard_title{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding: 30px 0;
    letter-spacing: 2px;
  }
  .standard_box{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin: 0 20px;
    flex-wrap: wrap;
    justify-content: left;
  }
  .standard_item{
    width: 32%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 2% 10px 0;
  }
  .standard_item:nth-child(3n){
    margin-right: 0;
  }
  .standard_item img{
    width: 100%;
    height: 100%;
  }
}
</style>
