import  {  createRouter,  createWebHistory  }  from  'vue-router'
import Index from "@/views/index/WebIndex";
import About from "@/views/about/WebAbout";
import Business from "@/views/business/WebBusiness.vue";

const  routes  =  [
    {
        name:'/',
        path:  '/',
        component:  Index
    },{
        name:'index',
        path:  '/index',
        component:  Index
    },
    {
        name:'/about',
        path:  '/about',
        component:  About
    },
    {
        name:'/business',
        path:  '/business',
        component:  Business
    }
]

const  router  =  createRouter({
    history:  createWebHistory(),
    scrollBehavior () {
        // 滚动到顶部
        return { top: 0 }
    },
    routes
})

export  default  router

