<template>
  <is-header></is-header>
  <router-view></router-view>
</template>

<script setup>
import IsHeader from '@/components/IsHeader.vue'
import IsFooter from '@/components/IsFooter.vue'
import {onMounted} from "vue";
import {ref} from "vue";
import { getCurrentInstance } from "vue";
import request from './utils/request'

const { proxy }= getCurrentInstance();


const setVarStyle = (key,value,dom = document.documentElement) => {
  dom.style.setProperty(key, value)
}
let phone = ref()
onMounted(() => {
  proxy.$53kf()
  setVarStyle('--el-color-primary', '#e1372a')
  setVarStyle('--el-color-primary-light-9', '#F5FBF0')
  setVarStyle('--el-color-primary-light-3', '#95d475')
  //  客服电话
  request.post('/home/Index/phone')
      .then(function (res) {
        phone.value = res.data.data.phone
      })
      .catch(function (error) {
        console.log(error);
      });
})
</script>

<style>
*{
  /*user-select: none;*/
}
body{
  margin: 0;
  background-color: #fafafa;
}
.container {
  width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1199px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }
}
</style>


