<template>
  <div>
    <div class="banner">
      <div class="banner_info container">
        <div class="banner_infoTitle">
          构建碳价值生态链<br>促进产业协作和价值创造
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="about container">
      <div class="left">
        <img src="../../../static/about_us.png">
      </div>
      <div class="right">
        <div class="about_title">关于我们</div>
        <div>
          <img src="../../../static/about_us.png">
        </div>
        <p>{{data.company}}</p>
      </div>
    </div>
    <!--  行业标准  -->
    <div class="standard">
      <div class="container">
        <div class="standard_title">我们坚持和遵循的行业标准</div>
        <div class="standard_box">
          <div class="standard_item" v-for="(item,index) in data.standard_list" :key="index">
            <img :src="item">
          </div>
        </div>
      </div>
    </div>
<!--  地图  -->
    <div class="map container">
      <div class="map_box" id="map"></div>
      <div class="map_bottom">
        <div class="left">
          <div class="title">公司地址</div>
          <div class="info">北京市海淀区信息路甲28号科实大厦B座1008室</div>
        </div>
        <div>
          <div class="title">24小时咨询热线</div>
          <div class="info">{{ data.phone }}</div>
        </div>
      </div>
    </div>
  </div>
  <is-footer :contact-show="false"/>
</template>
<script setup>
  import request from '../../utils/request'
  import {ref,onMounted} from "vue";
  import AMapLoader from '@amap/amap-jsapi-loader';
  import IsFooter from "@/components/IsFooter.vue";

  const data = ref({
    standard_list:[require('/static/standard1.png'),require('/static/standard2.png'),require('/static/standard3.png'),
      require('/static/standard4.png'),require('/static/standard5.png')],
    map_icon:require('/static/map_icon.png'),
    company:'',
    phone:'',
    address:''
  })

  const map = ref(null);
  const marker = ref(null);

  onMounted(() => {
    AMapLoader.load({
      key: '98ec0682853247a4058bdcbeba8a79e9', // 高德地图API Key
      securityJsCode: '0316113232f9cea64c4ca62d5f9c2ebb',
      version: '2.0', // 指定API版本
      plugins: ['AMap.Geolocation', 'AMap.PlaceSearch', 'AMap.Scale'], // 需要使用的插件
    }).then((AMap) => {
      map.value = new AMap.Map('map', {
        zoom: 18, // 初始缩放级别
        center: [116.312115,40.035204], // 初始中心点坐标
      });
      // 创建Marker
      marker.value = new AMap.Marker({
        position: [116.312115,40.035204], // 设置Marker的经纬度
        map: map.value, // 将Marker添加到地图上
        content: '<div class="custom-marker" style="padding:4px 0;border-radius:4px;box-shadow: 0 0 6px rgba(114, 103, 103, 0.1);text-align:center;width:110px;background-color:#fff;">' +
            '五纪时代' +'<span  style="margin-left:4px;color: #999;font-size: 14px;">前往</span>'+
            '</div>',
        offset: new AMap.Pixel(0, -30), // 调整内容位置
      });

      // 如果需要添加点击事件
      marker.value.on('click', function () {
        openNavigation();
      });
    }).catch((e) => {
      console.error(e);
    });
    getIntroduce();
    getPhone();
  });
  //公司简介
  const getIntroduce = ()=>{
    request.post('/home/Index/company').then((res) => {
      if(res.data.code == 200){
        data.value.company = res.data.data.company
        data.value.address = res.data.data.address
      }
    }).catch(function (error) {
      console.log(error);
    });
  }
  const getPhone = ()=>{
    request.post('/home/Index/phone').then((res) => {
      if(res.data.code == 200){
        data.value.phone =  res.data.data.phone
      }
    }).catch(function (error) {
      console.log(error);
    });
  }
  const openNavigation = () => {
    const targetLatitude = 116.312115;
    const targetLongitude = 40.035204;
    const targetName = '五纪时代';

    // 构造高德地图导航的URL
    const url = `amap://navi?sourceApplication=YourAppName&poiname=${encodeURIComponent(targetName)}&lat=${targetLatitude}&lon=${targetLongitude}&dev=1&style=2`;
    // 尝试打开高德地图进行导航
    if (window.navigator.userAgent.indexOf('AMap') !== -1) {
      // 如果设备上安装了高德地图应用，则使用URL Scheme打开
      window.webkit.messageHandlers.openURL.postMessage(null, url);
    } else {
      // 如果设备上没有安装高德地图，则使用网页版进行导航
      window.open('https://www.amap.com/search?id=B0H3J558H2&city=110108&geoobj=120.701672%7C41.55835%7C123.137561%7C42.499459&query_type=IDQ&query=%E4%BA%94%E7%BA%AA%E6%97%B6%E4%BB%A3(%E5%8C%97%E4%BA%AC)%E5%92%A8%E8%AF%A2%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&zoom=9.7');
    }
  };
</script>
<style scoped>
.banner{
  height: 530px;
  background: url("/static/index_banner.png") no-repeat;
  background-size: 100% 680px;
}
.banner_info{
  padding-top: 220px;
}
.banner_infoTitle{
  font-size: 44px;
  font-weight: 700;
}
/*  关于我们  */
.about{
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.about .left{
  width: 35%;
}
.about .left img{
  width: 100%;
}
.about .right{
  width: 60%;
}
.about .right p{
  line-height: 30px;
}
.about .right img{
  display: none;
}
.about .about_title{
  font-size: 34px;
  margin: 40px 0 20px;
}
.about .about_title:after{
  content: 'ABOUT US';
  display: block;
  color: #969696;
  font-size: 16px;
}
/*标准*/
.standard{
  padding: 80px 0;
  background-image: linear-gradient(310deg, rgb(243, 243, 255) 0%, rgb(207, 223, 234) 100%);
}
.standard_title{
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  padding: 80px 0;
  letter-spacing: 6px;
}
.standard_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}
.standard_item{
  width: 220px;
  height: 200px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
}
.standard_item img{
  width: 160px;
  height: 200px;
}

/* 地图 */
.map{
  padding-top: 100px;
}
.map_box{
  width: 1200px;
  height: 300px;
}
.map_bottom{
  display: flex;
  padding: 40px 200px 50px;
  justify-content: space-between;
}
.map_bottom .title{
  font-size: 26px;
  margin-bottom: 10px;
}
.map_bottom .info{
  font-size: 16px;
  color: #8c8c8c;
}

@media (max-width:1000px) {
  .banner {
    height: 150px;
    background: url("/static/index_banner.png") no-repeat;
    background-size: 100% 150px;
  }

  .banner_info {
    box-sizing: border-box;
    padding: 20px 20px;
  }

  .banner_infoTitle {
    font-size: 14px;
    font-weight: 700;
  }
  .about{
    margin-top: 0px;
  }
  .about .left{
    display: none;
  }
  .about .right{
    width: 100%;
    margin: 0 20px;
  }
  .about .right p{
    line-height: 20px;
  }
  .about .right img{
    width: 70%;
    margin: 0 auto;
    display: block;
  }
  .about .about_title{
    font-size: 34px;
    margin: 40px 0 20px;
  }
  .about .about_title:after{
    content: 'ABOUT US';
    display: block;
    color: #969696;
    font-size: 16px;
  }
  /** 行业标准 **/
  .standard{
    padding: 10px 0;
    background-image: linear-gradient(310deg, rgb(243, 243, 255) 0%, rgb(207, 223, 234) 100%);
  }
  .standard_title{
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    padding: 30px 0;
    letter-spacing: 2px;
  }
  .standard_box{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin: 0 20px;
    flex-wrap: wrap;
    justify-content: left;
  }
  .standard_item{
    width: 32%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 2% 10px 0;
  }
  .standard_item:nth-child(3n){
    margin-right: 0;
  }
  .standard_item img{
    width: 100%;
    height: 100%;
  }
  .map{
    padding-top: 20px;
  }
  .map_box{
    width: 100%;
    height: 150px;
  }
  .map_bottom{
    display: flex;
    padding: 20px 10px 30px;
    justify-content: left;
  }
  .map_bottom .title{
    font-size: 20px;
    margin-bottom: 10px;
  }
  .map_bottom .left{
    width: 50%;
    margin-right: 15px;
  }
  .map_bottom .info{
    font-size: 16px;
    color: #8c8c8c;
  }
}
</style>
