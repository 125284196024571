<template>
  <div>
    <div class="banner">
      <div class="banner_info container">
        <div class="banner_infoTitle">
          <span class="banner_infoTitleBox">产品碳足迹核算内容<i class="banner_icon">+</i></span>
          温室气体核查内容
        </div>
        <div class="banner_infoTitleText">评价+获证一站式服务高效产品碳足迹计算和全生命周期分析能力</div>
        <div class="banner_button" @click="chat">免费咨询</div>
      </div>
    </div>
<!--  碳足迹核算内容  -->
    <div class="content container">
      <div class="title">
        <img src="../../../static/icon/content_icon1.png">
        产品碳足迹核算内容
      </div>
      <div class="title_info">十多年的专业技术积累 认证 检测 分析 研发 一站式技术服务</div>
      <div class="content_info">
        <div class="left">
          <div>
            <img src="../../../static/content1.png">
          </div>
          <div>
            <img src="../../../static/content2.png">
          </div>
        </div>
        <div class="right">
          <div class="right_title">依据标准：</div>
          <div>ISO 14067《温室气体-产品碳足迹-量化要求及指南》<br>PAS 2050《商品和服务在生命周期内的温室气体排放评价规范》</div>
          <div class="right_title">标准要点：</div>
          <div>采用生命周期评价方法，为企业申请的产品提供“仅生产过程、摇篮到
            大门、摇篮到坟墓”等多项可选择的生命周期范围，还要明确核算的时
            间边界，统计分析产品在某确定时间周期内的碳排放相关数据。</div>
        </div>
      </div>
    </div>
<!-- 气体核查内容 -->
    <div class="content gas">
      <div class="container">
        <div class="title">
          <img src="../../../static/icon/content_icon2.png">
          温室气体核查内容
        </div>
        <div class="title_info">为各类组织在节能碳中和方面提供一站式服务，证书权威有效广泛认可</div>
        <div class="content_info">
          <div class="left">
            <div>
              <img src="../../../static/content3.png">
            </div>
          </div>
          <div class="right">
            <div class="right_title">依据标准：</div>
            <div>ISO 14064-1《温室气体-第一部分 组织层面上温室气体排放与清除量化及报告规范》</div>
            <div class="right_title">标准要点：</div>
            <div>范围1:直接(温室气体)排放来自公司拥有或控制的来源，例如，自有或控
              制的锅炉、熔炉、车辆等燃烧产生的排放范围 2:核算公司消耗的外购电
              力产生的(温室气体)排放量范围 3:排放是公司活动的结果，但并非由公
              司拥有或控制的来源(活动涵盖公司整个价值链的广泛范围，包括原材料
              、物流、团队商务旅行以及您的员工上班的方式。此类别通常占公司整
              体碳足迹的很大一部分)</div>
          </div>
        </div>
      </div>
    </div>
<!--  背景趋势  -->
    <div class="trend container">
      <div class="trend_top">
        <div class="trend_title">产品碳足迹核算/企业温室气体核查的背景趋势</div>
        <div class="trend_title_info">绿色低碳发展正从共识迈向行动，低碳是企业未来生存和发展的必然选择，企业应高度重视。</div>
        <div class="trend_title_info">预计未来主管当局将会考虑逐步加强重点行业强制性碳披露义务，各行业、地区将加快出台产品碳足迹核算/企业碳排放相关具体工作部署。</div>
        <div class="trend_line"></div>
      </div>
      <div class="trend_content">
        <div class="trend_content_title">国家战略、部委政策</div>
        <div class="trend_content_info">
          <span>我国双碳“1+N”政策体系已基本建立：</span>国务院及各部委、各省市相关政策已陆续发布。
        </div>
        <div class="trend_content_info">
          <span>党的二十大报告指出</span>，积极稳妥推进碳达峰碳中和，落实碳排放统计核算工作。
        </div>
        <div class="trend_content_info">
          <span>工信部</span>《信息通信行业绿色低碳发展行动计划（2022-2025年）》，落实碳排放统计核算工作。
        </div>
        <div class="trend_content_info">
          <span>国家发展改革委等联合印发</span>《关于加快建立统一规范的碳排放统计核算体系实施方案》：完善行业企业碳排放核算机制，建立健全重点产品碳排放核算方法。
        </div>
        <div class="trend_content_info">
          <span>《政府工作报告》建议2023年重点工作任务：</span>推动发展方式绿色转型，稳步推进节能降碳。
        </div>
      </div>
      <div class="trend_content">
        <div class="trend_content_title">行业、地区发展趋势</div>
        <div class="trend_content_info">
          2022年底，链主企业已在部分<span>产品招标要求中将企业温室气体核查和产品碳足迹报告列为加分项。</span>链主企业对供应链碳中和越来越重视。
        </div>
        <div class="trend_content_info">
          <span>31个省市区政府工作报告</span>中均对企业碳排放管理、发展绿色工厂/绿色产品等相关工作积极部署。
        </div>
        <div class="trend_content_info">
          <span>首个省级碳足迹方案出台：</span>《山东省产品碳足迹评价工作方案（2023—2025年）》提出，到2025年，基本完成600家重点企业产品碳足迹核算。
        </div>
      </div>
    </div>
<!--  企业优势  -->
    <div class="advantage container">
      <div class="advantage_title">为什么选择我们?</div>
      <div class="advantage_content">
        <div class="item">
          <div class="item_icon">快</div>
          <div class="item_txt">加急办理,快至5个工作日</div>
        </div>
        <div class="item">
          <div class="item_icon">多</div>
          <div class="item_txt">全国多地设立办 事处，34个省 市均可办理</div>
        </div>
        <div class="item">
          <div class="item_icon">稳</div>
          <div class="item_txt">专家一对一服务 不成功退全款</div>
        </div>
        <div class="item">
          <div class="item_icon">准</div>
          <div class="item_txt">专业的陪审员全 权负责内审、外 审服务</div>
        </div>
      </div>
      <div class="advantage_bottom">
        <div class="advantage_bottom_item">正规代办机构</div>
        <div class="advantage_bottom_item">签证认证合同</div>
        <div class="advantage_bottom_item">保障客户权益</div>
      </div>
    </div>

  </div>
  <is-footer/>
</template>

<script setup>
import {getCurrentInstance} from "vue";

const { proxy }= getCurrentInstance();
import IsFooter from "@/components/IsFooter.vue";

const chat = ()=>{
  proxy.$open53()
}
</script>

<style scoped>
.banner{
  height: 530px;
  background: url("/static/index_banner.png") no-repeat;
  background-size: 100% 680px;
}
.banner_info{
  padding-top: 220px;
}
.banner_infoTitle{
  font-size: 44px;
  font-weight: 700;
}
.banner_infoTitleBox{
  position: relative;
  margin-right: 30px;
}
.banner_infoTitleBox i{
  position: absolute;
  top: -20px;
  right: -35px;
  display: inline-block;
  font-weight: 700;
  color: #3fee97;
  font-style: normal;
}
.banner_infoTitleText{
  font-size: 14px;
  margin-top: 20px;
  font-weight: 700;
  color: #666;
}
.banner_button{
  margin-top: 30px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  padding: 15px 30px;
  font-weight: 700;
  border-radius: 40px;
  background-color: #3fee97;
}
.banner_button:hover{
  background-color: #62e3a8;
  cursor: pointer; /* 将鼠标指针样式设置为手型 */
}
/** 碳足迹内容 **/
.content{
  padding: 140px 0;
}
.title{
 text-align: center;
  font-size: 34px;
}
.title img{
  margin-right: 10px;
}
.title_info{
  text-align: center;
  color: #969696;
  padding: 20px 0;
}
.content_info{
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.content_info .left{
  box-sizing: border-box;
  padding-left:80px;
  flex: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_info .left div{
  margin-right: 40px;
}
.content_info .right{
  box-sizing: border-box;
  flex: 55%;
  padding-right: 130px;
  line-height: 22px;
}
.content_info .right_title{
  color: #0bbb38;
  font-weight: 700;
  padding: 2px 0;
}
.gas{
  background: linear-gradient(50deg, rgb(243, 243, 255) 0%, rgb(247, 253, 246) 100%);
}
/**背景趋势**/
.trend_title{
  margin: 50px 0 20px;
  font-size: 40px;
  text-align: center;
}
.trend_title_info{
  color: #969696;
  text-align: center;
}
.trend_line{
  position: relative;
  height: 2px;
  background-color: #0bbb38;
  margin: 60px 0 20px;
}
.trend_line:before,.trend_line:after{
  content: '';
  display: inline-block;
  position: absolute;
  right: 0;
  top: -3px;
  height: 8px;
  width: 68px;
  background-color: #0bbb38;
  border-radius: 4px;
}
.trend_line:after{
  left: 0;
}
.trend_content_title{
  font-size: 24px;
  font-weight: 700;
  padding: 15px 0;
}
.trend_content_info{
  line-height: 34px;
}
.trend_content_info span{
  color: #0bbb38;
}

/** 我们优势  **/
.advantage_title{
  font-size: 40px;
  margin: 70px 0 40px;
  text-align: center;
}
.advantage_content{
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
}
.advantage_content .item{
  width: 259px;
  background-color: #ebf5f7;
  text-align: center;
}
.advantage_content .item_icon{
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #34d5b5;
  color: #fff;
  font-weight: 700;
  font-size: 44px;
  line-height: 125px;
  margin: -65px auto 0;
  text-align: center;
}
.advantage_content .item_icon:after{
  content: '';
  display: block;
  height: 8px;
  width: 110px;
  margin: 20px auto 0;
  border-radius: 4px;
  background-color: #88f6d0;
}
.advantage_content .item_txt{
  padding: 60px 60px 60px;
  font-size: 22px;
}
.advantage_bottom{
  padding: 80px 70px 140px;
  display: flex;
  justify-content: space-between;
}
.advantage_bottom_item{
  width: 30%;
  font-size: 27px;
  padding: 10px 0;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #34d5b5;
}

@media (max-width:1000px) {
  .banner{
    height: 150px;
    background: url("/static/index_banner.png") no-repeat;
    background-size: 100% 150px;
  }
  .banner_info{
    box-sizing: border-box;
    padding: 20px 20px;
  }
  .banner_infoTitle{
    font-size: 14px;
    font-weight: 700;
  }
  .banner_infoTitleBox{
    position: relative;
    margin-right: 10px;
  }
  .banner_infoTitleBox i{
    position: absolute;
    top: -10px;
    right: -10px;
    display: inline-block;
    font-weight: 700;
    color: #3fee97;
    font-style: normal;
  }
  .banner_infoTitleText{
    font-size: 8px;
    margin-top: 10px;
    font-weight: 700;
    color: #666;
  }
  .banner_button{
    margin-top: 10px;
    display: inline-block;
    color: #fff;
    font-size: 10px;
    padding: 5px 10px;
    font-weight: 700;
    border-radius: 20px;
    background-color: #3fee97;
  }

  /** 碳足迹内容 **/
  .content{
    padding: 30px 0;
  }
  .title{
    text-align: center;
    font-size: 16px;
  }
  .title img{
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .title_info{
    font-size: 10px;
    text-align: center;
    color: #969696;
    padding: 10px 0;
  }
  .content_info{
    display: block;
    align-items: center;
    margin-top: 10px;
  }
  .content_info .left img{
   width: 100px;
    height: 100px;
  }
  .content_info .left{
    box-sizing: border-box;
    padding-left:30px;
    flex: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content_info .left div{
    margin-right: 20px;
  }
  .content_info .right{
    box-sizing: border-box;
    flex: 55%;
    padding: 0 20px;
    font-size: 14px;
    line-height: 16px;
  }
  .content_info .right_title{
    color: #0bbb38;
    font-weight: 700;
    padding: 2px 0;
  }
  .gas{
    background: linear-gradient(50deg, rgb(243, 243, 255) 0%, rgb(247, 253, 246) 100%);
  }

  .trend_content{
    margin: 0 20px;
  }
  .trend_top{
    display: none;
  }
  .trend_content_info{
    line-height: 30px;
  }
  /** 我们优势  **/
  .advantage_title{
    font-size: 24px;
    margin: 30px 0 20px;
    text-align: center;
  }
  .advantage_content{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 80px 10px 0;
  }
  .advantage_content .item{
    width: 49%;
    background-color: #ebf5f7;
    text-align: center;
    margin-bottom: 80px;
  }
  .advantage_content .item_icon{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #34d5b5;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 100px;
    margin: -50px auto 0;
    text-align: center;
  }
  .advantage_content .item_icon:after{
    content: '';
    display: block;
    height: 8px;
    width: 70px;
    margin: 20px auto 0;
    border-radius: 4px;
    background-color: #88f6d0;
  }
  .advantage_content .item_txt{
    padding: 60px 20px 60px;
    font-size: 20px;
  }
  .advantage_content .item:nth-child(n+3){
    width: 49%;
    background-color: #ebf5f7;
    text-align: center;
    margin-bottom: 20px;
  }
  .advantage_bottom{
    padding: 10px 70px 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .advantage_bottom_item{
    width: 99%;
    font-size: 22px;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    background-color: #34d5b5;
    margin-bottom: 10px;
  }
}
</style>