import { createApp } from 'vue'
import App from './App.vue'
import  router  from  './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const  app  =  createApp(App)

app.use(ElementPlus)
app.use(router)
app.config.globalProperties.$baseUrl= "https://company.fxd1.com.cn"

//创建53客服
let _53code = ''
let s = ''
app.config.globalProperties.$53kf = ()=>{
    _53code = document.createElement("script");
    _53code.src = "https://tb.53kf.com/code/code/e94c710303a77fc94e3e8b53cf4f1bfb9/3";
    s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(_53code, s);
}
//打开53客服
app.config.globalProperties.$open53 = ()=>{
    const _53api = $53.createApi();    //$53这个对象是从53挂码获取的
    _53api.push('cmd', 'kfclient');
    _53api.push('type', 'popup');    //popup是弹窗，new是新窗口。浮动窗口就是页面右下角弹出的小窗口，新窗口是一个相对大的浮层窗口，可以自己测试看下
    //_53api.push('worker_id', '1006');    //这个参数用于指定接待客服。正常不用设置，会按照53后台设置的接待规则分配客服。worker_id是客服的工号，用于指定接待客服，如果不设置，会按照后台设置的接待规则分配客服。账号对应的工号可以到工号管理中查看。工号管理登录地址 http://open.53kf.com/?go_url=https://saasjh.53kf.com/2mastersaas
    _53api.query();
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')

