<template>
  <div class="contact" v-if="props.contactShow">
    <div class="container">
      <div class="contact_title">联系我们</div>
      <div class="contact_info">
        <div class="contact_info_item">
          <div class="item_title">服务时间</div>
          <div class="item_bottom">每周一至五9：00-18：00</div>
        </div>
        <div class="contact_info_item">
          <div class="item_title">咨询电话</div>
          <div class="item_bottom">{{ phone }}</div>
        </div>
        <div class="contact_info_item">
          <div class="item_title">咨询邮箱</div>
          <div class="item_bottom">wujitimes@88.com</div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="footer_txt">© 2023 五纪时代（北京）咨询有限公司 版权所有<a href="https://beian.miit.gov.cn/">京ICP备19037573号-4</a></div>
    </div>
  </div>
</template>

<script setup>
import {defineProps} from 'vue'
import request from '../utils/request'
import {ref,onMounted} from "vue";
const phone = ref('')
const props = defineProps({
  contactShow:{
    type:Boolean,
    default:true,
    required:false
  },
})
onMounted(()=>{
  getPhone();
})

const getPhone = ()=>{
  request.post('/home/Index/phone').then((res) => {
    if(res.data.code == 200){
      phone.value =  res.data.data.phone
    }
  }).catch(function (error) {
    console.log(error);
  });
}
</script>

<style scoped>
  .footer{
    background-color: #333;
    color: #ffffff;
    padding: 30px 0;
  }
  .footer a{
    color: #fff;
    text-decoration: none;
  }
  .footer_txt{
    text-align: center;
  }
  .contact{
    box-sizing: border-box;
    width: 100%;
    height: 390px;
    color: #fff;
    background: url("/static/contact_bg.png") no-repeat;
    background-size: 100% 390px;
  }
  .contact .contact_title{
    font-size: 30px;
    margin-bottom: 50px;
    letter-spacing: 2.67px;
    font-weight: 700;
    text-align: center;
    padding-top: 100px;
  }
  .contact_info{
    display: flex;
    padding: 0 60px;
  }
  .contact_info .contact_info_item{
    box-sizing: border-box;
    width: 360px;
    text-align: center;
    padding-bottom: 12px;
  }
  .contact_info .contact_info_item:nth-child(2){
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }
  .contact_info .item_title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .contact_info .contact_info_item .item_bottom{
    font-size: 18px;
  }
  @media (max-width: 1200px){
    .contact{
      padding-bottom: 20px;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      color: #fff;
      background: linear-gradient(50deg,#30d466,#0aaff6);
      background-size: 100%;
    }
    .contact .contact_title{
      font-size: 14px;
      margin-bottom: 20px;
      letter-spacing: 1.67px;
      font-weight: 700;
      text-align: center;
      padding-top: 20px;
    }
    .contact_info{
      display: flex;
      padding: 0 20px;
    }
    .contact_info .contact_info_item{
      box-sizing: border-box;
      width: 360px;
      text-align: center;
      padding-bottom: 12px;
    }
    .contact_info .contact_info_item:nth-child(2){
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
    .contact_info .item_title{
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .contact_info .contact_info_item .item_bottom{
      font-size: 10px;
    }
    .footer{
      margin-bottom: 50px;
    }
  }
</style>
