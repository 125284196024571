<template>
  <div class="header">
    <div class="top top_container">
      <div class="top_logo">
        <div class="logo">
          <img src="../../static/logo.png" alt="logo">
        </div>
        <div class="logo_text">
          <img src="../../static/logo_text.png" alt="logo_text">
        </div>
      </div>
      <div class="top_right">
        <div class="header_more_btn" @click="drawer = true">
          <el-icon size="40px"><Menu /></el-icon>
        </div>
        <div class="nav">
          <div :class="currentPath == item.path ? 'active' : (currentPath == item.child ? 'active' :'')"
               v-for="(item,index) in navList" :key="index" @click="navTo(item.path)">
            <text>{{ item.name }}</text>
            <div class="dropdown" v-if="item.list">
              <div class="dropdown_item" @click="navTo(item.path,item1.id)" v-for="(item1,index) in item.list" :key="index">{{item1.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-drawer v-model="drawer" title="" size="50%">
      <div class="drawer">
        <div class="item" v-for="(item,index) in navList" :key="index" @click="navTo(item.path)">{{ item.name }}</div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useRouter } from "vue-router";

import { ref } from 'vue'
const drawer = ref(false)//侧边弹出

const dropdown = ref(false)
// 从 vue 中引入defineProps
import {computed} from 'vue'

//获取当前路径
const route = useRoute();
//路由跳转
const router = useRouter();


let navList = [{
  name:'首页',
  path:'/'
},{
  name:'业务板块',
  path:'/business'
},{
  name:'关于我们',
  path:'/about'
}]
const currentPath = computed(()=>{
  if(route.fullPath.indexOf('?')>0){
    return route.fullPath.substring(0,route.fullPath.indexOf('?'))
  }else{
    return route.fullPath
  }
})

const navTo = (path)=>{
  drawer.value = false
  dropdown.value = false
  router.push({
    path:  path,
  });
}
</script>

<style scoped>
  .header{
    width: 100%;
    background-color: #fff;
  }
  .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 75px;
    height: 85px;
    user-select: none;
  }
  .top_container{
    max-width: 1200px;
    margin: 0 auto;
  }
  .top_logo{
    display: flex;
    align-items: center;
  }
  .logo:after{
    display: inline-block;
    content: '';
    width: 1px;
    margin: 10px 20px;
    height: 30px;
    background-color: #d5d5d5;
  }
  .top_right{
    display: flex;
    align-items: center;
    height: 100%;
  }
  .header_more_btn{
    display: none;
  }
  .top_right .nav{
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 30px;
  }
  .top_right .nav .dropdown{
    display: none;
    position: absolute;
    top:74px;
    left: 0;
    background-color: #fff;
    z-index: 99;
    width: 150px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(51,51,51,0.1);
    text-align: center;
    line-height: 50px;
    opacity:0.4;
  }
  .top_right .nav .dropdown .dropdown_item:hover{
    color: #333;
  }
  .top_right .nav >div{
    box-sizing: border-box;
    position: relative;
    padding: 0 10px;
    margin: 0 20px;
    font-weight: 600;
    height: 85px;
    line-height: 85px;
    cursor: pointer;
  }
  .top_right .nav .active{
    border-bottom: 4px solid #0bbb38;
  }
  .top_right .nav .active text{
    color: #333;
  }
  .top_right .nav >div:hover{
    border-bottom: 4px solid #0bbb38;
  }
  .top_right .nav >div:hover text{
    color: #333;
  }
  .top_right .nav >div:hover .dropdown{
    display: block;
    animation: run1 0.3s linear 0s 1 forwards;
  }
  @keyframes run1 {
    100% {
      opacity: 1;
    }
  }
  .feature{
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .feature div{
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 14px;
  }
  .feature div img{
    margin-right: 10px;
  }
  .phone{
    display: flex;
    /*align-items: center;*/
  }
  .phone img{
    margin-right: 5px;
    width: 30px;
    height: 30px;
  }
  .phone_right i{
    font-size: 20px;
    color: #df3326;
    font-weight: 700;
  }
  .phone_right div{
    font-size: 14px;
  }

  .drawer{
    padding: 0;
  }
  .drawer .item{
    padding: 10% 0;
    text-align: center;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    font-weight: 600;
  }
  .drawer .dropdown_btn{
    position: relative;
  }
  .drawer .dropdown_btn .icon{
    position: absolute;
    right: 2%;
    top: 0;
  }
  .drawer .dropdown_btn .icon_dropdown{
    animation: overturn 0.3s linear 0s 1 forwards;
  }
  @keyframes overturn {
    100% {
      transform: rotate(180deg);
    }
  }
  .drawer .item:last-child{
    border-bottom: none;
  }
  .drawer .dropdown{
    margin-top: 3%;
    background-color: #f0f3fc;
  }
  .drawer .dropdown .dropdown_item{
    padding: 8% 0;
    border-bottom: 1px solid #d9d9d9;
  }
  .drawer .dropdown .dropdown_item:last-child{
    border-bottom: none;
  }
  @media (max-width: 1700px){
    .feature{
      display: none;
    }
  }
  @media (max-width: 1500px){
    .phone{
      display: none;
    }
  }
  @media (max-width: 1300px) and (min-width:1199px){
    .top_right .nav div{
      margin: 0 10px;
    }
    .top_container{
      padding: 0;
      width: 1200px;
      margin: 0 auto;
    }
  }
  @media (max-width: 1199px) {
    .top{
      height: auto;
    }
    .top_container{
      padding: 3% 5%;
      padding: 1% 5%;
    }
    .logo{
      width: 100px;
      /*height: 50px;*/
    }
    .logo img{
      width: 100%;
      height: 100%;
    }
    .header_more_btn{
      display: block;
    }
    .top_right .nav{
      display: none;
    }
    .logo:after{
      display: none;
    }
    .logo_text{
      display: none;
    }
  }
</style>
